<template>
  <Fragment>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-btn @click.stop="modalFormulario = true" class="primary" block>
          <v-icon color="white"> mdi-mail </v-icon> &nbsp &nbsp &nbsp Enviar
          correo
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-dialog v-model="modalFormulario" persistent>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-card>
                <v-card-title>
                  <h3 class="text-center">Enviar correos a proveedores</h3>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="6" md="2">
                      <v-switch
                        v-model="form.especifica"
                        label="Notificación específica"
                      />
                    </v-col>
                    <v-col v-if="form.especifica">
                      <v-autocomplete
                        outlined
                        :items="listado_acciones"
                        item-text="texto"
                        item-value="accion"
                        clearable
                        v-model="form.accion"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-if="!form.especifica">
                    <v-col cols="6" md="2">
                      <v-switch
                        v-model="form.todos"
                        label="Notificar a todos los perfiles"
                      />
                    </v-col>
                    <v-col>
                      <v-autocomplete
                        v-if="!form.todos"
                        v-model="form.perfiles"
                        :items="perfiles"
                        item-text="nombre"
                        item-value="id"
                        outlined
                        clearable
                        label="Seleccione los perfiles a los que notificará"
                        multiple
                        chips
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="form.asunto"
                        outlined
                        clearable
                        label="Asunto del correo"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12">
                      <app-rich-text-component v-model="form.mensaje" />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-btn class="secondary" block @click.stop="validarForm">
                        Enviar
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-btn block @click.stop="cerrarForm">Cerrar</v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <ConfirmationDialog
          :show="modalConfirmacion"
          title="Deseas enviar este correo?"
          message="Se enviará a los perfiles seleccionados."
          btnConfirmar="ENVIAR"
          @close="modalConfirmacion = false"
          @confirm="enviarCorreo"
        />
      </v-col>
    </v-row>
  </Fragment>
</template>
<script>
import { Fragment } from "vue-fragment";
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";

export default {
  components: { Fragment, ConfirmationDialog },
  name: "enviarCorreosAdmin",
  data: () => ({
    form: {
      perfiles: [],
      todos: false,
      especifica: false,
      mensaje: "",
      asunto: "",
      accion: null,
    },
    modalFormulario: false,
    modalConfirmacion: false,
    perfiles: [],
    listado_acciones: [
      {
        accion: "notificacion_procesos_contratacion",
        texto: "Notificar de procesos pendientes de pasar a contratación",
      },
    ],
  }),
  methods: {
    validarForm() {
      if (this.form.especifica && !this.form.accion) {
        return this.pushAppMessage({
          type: "warning",
          message:
            "Si es una notificación específica, debe decir el tipo de acción",
        });
      } else if (!this.form.especifica && !this.form.todos && this.form.perfiles.length === 0) {
        return this.pushAppMessage({
          type: "warning",
          message: "Seleccione al menos un perfil",
        });
      }
      if (this.form.mensaje === "") {
        return this.pushAppMessage({
          type: "warning",
          message: "Ingrese un mensaje",
        });
      }
      if (this.form.asunto === "") {
        return this.pushAppMessage({
          type: "warning",
          message: "Ingrese el asunto",
        });
      }
      this.modalConfirmacion = true;
    },
    async enviarCorreo() {
      await this.services.Notificaciones.enviarNotificacionesCorreo(this.form);

      this.modalConfirmacion = false;

      this.cerrarForm();
    },
    cerrarForm() {
      this.modalFormulario = false;
      this.form = {
        perfiles: [],
        todos: false,
        mensaje: "",
        asunto: "",
      };
    },
    async listarPerfiles() {
      const { data } = await this.services.Notificaciones.listarPerfiles();
      this.perfiles = data;
    },
  },
  async mounted() {
    await this.listarPerfiles();
  },
};
</script>
