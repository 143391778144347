<template>
    <Fragment>
        <v-row>
            <v-col cols="12" md="12">
                <v-btn block class="primary" @click="(confirmacion_modal = true)"><v-icon class="mr-2"color="white">mdi-logout</v-icon>Actualizar estados proceso compra (Contratos)</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <ConfirmationDialog :show="confirmacion_modal" title="¿DESEAS CAMBIAR EL ESTADO DE LOS PROCESOS COMPRA?"
                    message="De confirmarse, TODOS LOS PROCESOS DE COMPRAS CON CONTRATOS SERAN ACTUALIZADOS A SEGUIMIENTO CONTRACTUAL Y LIQUIDACION DEL SISTEMA" btnConfirmar="CONFIRMAR"
                    @close="confirmacion_modal = false" @confirm="actualizarEstado" />
            </v-col>
        </v-row>
    </Fragment>
</template>

<script>
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";
import { Fragment } from 'vue-fragment';

export default {
    name: 'CerrarSessiones',
    components: {
        ConfirmationDialog,
        Fragment
    },
    data: () => ({
        confirmacion_modal: false,
    }),
    methods: {
        async actualizarEstado() {
            const { data } = await this.services.ContratoService.ejecutarLiquidacionContratosPrevios();
            
            this.confirmacion_modal = false;
        },
    }
}
</script>